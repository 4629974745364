import React from 'react';
import NavBar from './Navbar/NavBar';
import Services from './Services';

import Footer from './Footer';

// import './AboutUs.css'



const AboutUs = () => {
    return (
      <>
      <NavBar></NavBar>
      <Services></Services>
     <Footer></Footer>
      
      </>
    )
}


export default AboutUs;



   