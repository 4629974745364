import { hover } from '@testing-library/user-event/dist/hover';
import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import './NavBar.css'

const NavLinks = () => {
    return (
        <>
            <HashLink  className="px-4 font-extrabold navcolor" smooth to="/about">
                About
            </HashLink>
            {/* <HashLink className="px-4 font-extrabold navcolor" smooth to="/services">
               Works
            </HashLink> */}
            <HashLink className="px-4 font-extrabold navcolor" to="/">
                Blog    
            </HashLink>
            <HashLink className="px-4 font-extrabold navcolor" to="/contact">
                Contact Us
            </HashLink>
            <HashLink className="px-4 font-extrabold navcolor" to="/ourteam">
                Gallery
            </HashLink>
            <Link className="text-white navbtn hover:bg-orange-600 inline-flex items-center justify-center w-auto px-6 py-3 shadow-xl rounded-xl"  smooth to="/JoinUs">
                Join Us
            </Link>
        </>
    )
}

export default NavLinks;