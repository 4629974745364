import React, { useEffect } from 'react';
import AOS from 'aos';
import "aos/dist/aos.css";
import './index.css';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
// All pages
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import PrivacyPolicyforJJP from "./components/privacy/PrivacyPolicyforJJP";
import PrivacyPolicyforOBH from "./components/privacy/PrivacyPolicyforOBH";
import PrivacyPolicyforSYC from "./components/privacy/PrivacyPolicyforSYC";

import {useDocTitle} from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';
import OurTeam from './pages/OurTeam';
import AboutUs from './components/AboutUs';
import JoinUs from './JoinUs';
import TermsConditions from './components/TermsConditions/TermsConditions';

function App() {
  useEffect(() => {
    const aos_init = () => {
      AOS.init({
        once: true,
        duration: 1000,
        easing: 'ease-out-cubic',
      });
    }

    window.addEventListener('load', () => {
      aos_init();
    });
  }, []);

  useDocTitle("SYADWAD YUVA CLUB");

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/ourteam" element={<OurTeam />} /> 
            <Route path="/privacypolicyforJJP" element={<PrivacyPolicyforJJP />} />
            <Route path="/privacypolicyforOBH" element={<PrivacyPolicyforOBH />} /> 
            <Route path="/privacy" element={<PrivacyPolicyforSYC />} />  
            <Route path="/about" element={<AboutUs></AboutUs>} />  
            <Route path="/JoinUs" element={<JoinUs></JoinUs>} />  
            <Route path="/TermsConditions" element={<TermsConditions></TermsConditions>} />  

            
            

           
          </Routes>
        </ScrollToTop>
      </Router>
    </>
  );
}


export default App;
