import React from 'react';
import npr from "../images/npr.jpeg"
import NavBar from '../components/Navbar/NavBar';
import { Link } from 'react-router-dom';
import heroImg from '../images/web-dev.svg';
import nimit from '../images/nimit.jpeg';
import puru from '../images/puru.jpeg';
import Footer from '../components/Footer';


const OurTeam = () => {
    return (
        <>
   <div >
    <div>
      <NavBar />
             </div>
                


<div className="m-auto overflow-hidden mx-4 mt-8 lg:mt-4 p-2 md:p-12 h-5/6" data-aos="zoom-in">

<div className="flex flex-col lg:flex-row py-8 justify-between text-center lg:text-left">
{/*    
<div class="grid grid-cols-2 md:grid-cols-2 gap-10 ">
    
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={nimit} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Nimit Jain</h5>
        <h3 className="" style={{color:"Black",marginTop:"0px"}}>Founder & CEO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    </div>
</a>

    </div>
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={puru} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-0 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Purushottam Kumar</h5>
        <h3 className="" style={{color:"Black"}}>Co-Founder & COO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far.</p>
    </div>
</a>

    </div>
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={nimit} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Nimit Jain</h5>
        <h3 className="" style={{color:"Black",marginTop:"0px"}}>Founder & CEO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    </div>
</a>

    </div>
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={nimit} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Nimit Jain</h5>
        <h3 className="" style={{color:"Black"}}>Founder & CEO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    </div>
</a>

    </div>
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={nimit} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Nimit Jain</h5>
        <h3 className="" style={{color:"Black",marginTop:"0px"}}>Founder & CEO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    </div>
</a>

    </div>
    <div>      
<a href="#" className="flex flex-col items-centerbg-white border border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700">
    <img className="object-cover w-full rounded-t-lg h-96 md:h-auto md:w-full md:rounded-none md:rounded-l-lg" style={{height:"45vh"}} src={nimit} alt=""/>
    <div className="flex flex-col justify-between p-10 leading-normal">
        <h5 className="mb-2 text-2xl font-bold tracking-tight text-blue-900 dark:text-white">Nimit Jain</h5>
        <h3 className="" style={{color:"Black"}}>Founder & CEO</h3>
        <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">Here are the biggest enterprise technology acquisitions of 2021 so far, in reverse chronological order.</p>
    </div>
</a>

    </div>
    </div> */}
</div>
</div>            
                    
                

<Footer/>

</div>

        </>
    )
}
export default OurTeam;
