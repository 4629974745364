import React from 'react';
import heroImg from '../images/syclogo.png';

const Services = () => {

    return (
        <div id="services" className="bg-gray-100 py-12" >
            <section data-aos="zoom-in-down">
                    <div className="my-4 py-4">
                        <h2 className="my-2 text-center text-3xl text-amber-900 uppercase font-bold hover:text-orange-500 cursor-pointer">Syadwaad Yuva Club</h2>
                        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-orange-900">An organization that gives equal respect to the views of others.</h2>
                    </div>

                    <div className="px-12" data-aos="fade-down" data-aos-delay="600">
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5 ">
                            
                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-950 hover:bg-orange-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t float-right duration-1000 " src={heroImg} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Social Achievements</h2>
                                    <p className="text-md font-medium">
                                    •Establishment and operation of Bridhashram in Trilok Teerth Dham<br/>•Establishment and operation of cow shelter
                                    </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-950 hover:bg-orange-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                <img alt="card img" className="rounded-t float-right duration-1000 " src={heroImg} />
                                    <h2 className="font-semibold my-4 text-2xl text-center">Religious Achievements</h2>
                                    <p className="text-md font-medium">•Consecration and worship in more than 500 different temples across India.<br/>•Consecration and worship in foreign temples also.<br/>• Arrangements for uninterrupted diet of thousands of Sadhus and Sadhvis.<br/>•Special role in movements like Santhara and Sammed Shikhar. </p>
                                </div>
                            </div>

                            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-slate-950 hover:bg-orange-500 hover:text-white rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 contents-center text-sm">
                                <img alt="card img" className="rounded-t float-right duration-1000 " src={heroImg} />
                                    <h2 className="font-semibold my-4 text-2xl text-center ">Operation of Syadwaad Academy</h2>
                                    <p className="text-md font-medium">•24*7 Blood Donation Camp (donating blood to needy people by going to hospital).<br/>• Providing help to flood victims in Kerala.<br/>•Other:- Secretly helping hundreds of needy people.<br/>•Achievements of Corona period:-<br/>•Food distribution to thousands of needy homes.<br/>•Providing facilities like oximeter, ventilator, sanitizer, mask, PPT kit etc.  </p>
                                </div>
                            </div>

                                               
                        </div>
                    </div>
            </section>

            <section>
                <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
                    <div className="flex flex-col-reverse lg:flex-row py-8 justify-between lg:text-left" data-aos="zoom-out">
                        <div className="flex items-center">

                            <div>
                            <h3 className="text-3xl flex contents-center text-orange-900 
                            font-bold hover:text-orange-500 cursor-pointer">Syadwaad Yuva Club</h3>
                                <p className='my-3 text-xl text-slate-900 font-semibold'>
                                syadwaad is an organization whose thinking is above the thinking of Digambara, Swetambar, Sthanakavasi, Beas Panthi, Terah Panthi. Which believes in spreading the message of Lord Mahavir to the people.
                                syadwaad is a thought that explains this. That as you are saying, understanding or doing. Not only that is right, but someone else's opinion and understanding can also be right.
                                "An organization that gives equal respect to the views of others."
                                "syadwaad Youth Club" is a boon for those people who want to give a golden and secure future to religion and society but due to some reason they are unable to do so. This organization provides an opportunity to such people to make their mark in the society. Does it.
                                Good thinking is the mother of good work.
                                And good work is the result of good thinking.Today more than 1000 youth are providing their services in this organization and their number is continuously increasing.

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;