import React,{Fragment, useState} from "react";
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';
import img1 from '../../images/bloodbag.png'; 
import img2 from '../../images/kumbh-kalash.png';
import img3 from '../../images/helping.png';
import img4 from '../../images/charity.png';
import img5 from '../../images/plant-pot.png';
import img6 from '../../images/shopping-bag.png';
import img7 from '../../images/mask.png';
import img8 from '../../images/food.png';
// import VisibilitySensor from "react-visibility-sensor"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeartPulse ,faUserDoctor } from '@fortawesome/free-solid-svg-icons'
// Import Swiper React components
import { Swiper, SwiperSlide, } from 'swiper/react';
import { Autoplay } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';

const Counter = ({className, ...rest}) => {
    const [counterOn, setCounterOn] = useState(false)
    return (
    
    <ScrollTrigger onEnter={()=> setCounterOn(true)} onExit={()=>setCounterOn(false)}>
      <Swiper
                         spaceBetween={40}
                         slidesPerView={4}
                         onSlideChange={() => console.log('slide change')}
                         onSwiper={(swiper) => console.log(swiper)}>
                       
                        <SwiperSlide> 
                            
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm">
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px",color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img1}  style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}}/>
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={1250} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Blood Units Donated</h2>
                                
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide> 
                        <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faUserDoctor} fade style={{height:"140px", color:"red", }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                               
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img2}  style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}}/>
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={500} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Weekly Abhishek</h2>
                                
                                </div>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px", color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img3}  style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={15} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Health Camp</h2>
                                
                                </div>
                            </div></SwiperSlide>
                        <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px", color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img4} style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={10} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Cloths Donation</h2>
                                
                                </div>
                            </div></SwiperSlide>
                        <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px", color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img5} style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={3000} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Trees Planted</h2>
                                
                                </div>
                            </div></SwiperSlide>

                            <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px",color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img6} style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={50000} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Jute Bags Distributed</h2>
                                
                                </div>
                            </div></SwiperSlide>
                            <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px", color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img7} style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={1} duration={2} delay={0}/>}Lakh +</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Mask Distributed</h2>
                                
                                </div>
                            </div></SwiperSlide>

                            <SwiperSlide> <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 rounded-lg shadow-2xl p-3 group">
                                <div className="m-2 text-justify text-sm" style={{textAlign:"center"}}>
                                {/* <FontAwesomeIcon icon={faHeartPulse} style={{height:"140px", color:"red" }} className="rounded-t  transition duration-1000 ease-in-out" /> */}
                                <img alt="card img" className="rounded-t  transition duration-1000 ease-in-out" src={img8} style={{height:"140px", display:"block",marginLeft:"auto",marginRight:"auto"}} />
                                    <h1 className="font-semibold my-4  text-center" style={{fontSize:"50px"}}> {counterOn && <CountUp start={0} end={20000} duration={2} delay={0}/>}+</h1>
                                  <h2 className="font-semibold my-2 text-2xl text-center">Thali Distributed</h2>
                                
                                </div>
                            </div></SwiperSlide>
                        
                        </Swiper>
      
      
       
       
        </ScrollTrigger>
)}

export default Counter;