import React from 'react';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';

const NavLinks = () => {
    return (
        <>
                  <h1>PrivacyPolicyforOBH</h1>
        </>
    )
}

export default NavLinks;